export function initShowActionAction() {
    return {
      type: "INIT_SHOW_ACTION",
    };
  }
  export function ShowActionSuccess() {
    return {
      type: "GET_SHOW_ACTION",
    };
  }
  export function ShowActionError() {
    return {
      type: "ERROR_SHOW_ACTION",
    };
  }